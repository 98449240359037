<main class="pls pls">
  <app-cms-page-header pageTitle="Welcome"></app-cms-page-header>
  <section
    class="pls__hero"
    style="background-image: url('{{ backgroundImage }}') !important"
    [ngClass]="{
      'pls__hero--no-overlay':
        cmsService?.cmsSettings?.getValue()?.state === 'Iowa'
    }"
  >
    <div class="pls-container">
      <div class="pls-card">
        <p>
          This professional learning system, PLS, is designed to assist you in
          successfully navigating and utilizing
          {{ cmsService.cmsSettings.getValue()?.project }}; the statewide
          special education management system for
          {{ cmsService.cmsSettings.getValue()?.state }}.
          {{ cmsService.cmsSettings.getValue()?.project }} will assist in the
          facilitation and development of IEPs/IFSPs, monitoring student
          progress, instructional decision making, developing local reports, and
          gathering data for state and federal
        </p>
        <button class="btn cursor-pointer">Log In</button>
      </div>
    </div>
  </section>

  <div class="pls-container py-3">
    <app-cms-tiles [tiles]="tiles"></app-cms-tiles>

    <div class="start">
      <button class="btn">Start Here</button>
    </div>
  </div>
</main>
