<ng-template #nonExpandable>
  <a
    mat-list-item
    [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
    routerLinkActive="active"
    (click)="onItemSelected(item)"
    [ngClass]="{
      active: isLinkActive(),
      expanded: isExpanded(),
      'text-bold': isExpanded()
    }"
    [appScrollIntoView]="isLinkActive()"
    class="menu-list-item text-md"
  >
    {{ item.displayName }}
  </a>
</ng-template>

<ng-container *ngIf="!item.disabled">
  <ng-container *ngIf="item?.children?.length > 0; else nonExpandable">
    <mat-accordion>
      <mat-expansion-panel
        class="mat-elevation-z0 nav-expansion-panel"
        [expanded]="isExpanded()"
        >
        <mat-expansion-panel-header
          class="position-relative"
          (click)="$event.stopPropagation()"
        >
          <mat-panel-title
            class="display-flex align-items-center nav-panel-title"
            [routerLink]="item.route"
            (click)="onItemSelected(item, $event)"
            [ngClass]="{ 'text-bold': isExpanded() }"
          >
            <span
              [routerLink]="item.route"
              routerLinkActive="active"
              [ngClass]="{ active: isLinkActive(), expanded: isExpanded() }"
              [appScrollIntoView]="isLinkActive()"
            ></span>
            {{ item.displayName }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <app-menu-list-item
            tabindex="0"
            [item]="child"
            [depth]="getChildDepth()"
            *ngFor="let child of item.children"
          ></app-menu-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</ng-container>
