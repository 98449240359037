import { PageType } from 'src/app/shared/models/nav-item.model';

export class SiteMap {
  originalPageId: string = '';
  parentId: string = '';
  sortOrder: number;
  title: string = '';
  navigationTitle: string = '';
  metaIndex: boolean;
  metaPriority: number;
  menuTitle: string = '';
  pageTypeName: PageType;
  permalink: string = '';
  isHidden: boolean;
  published: string = '';
  created: string = '';
  lastModified: string = '';
  permissions: string[];
  id: string = '';
  level: number;
  items: SiteMap[];
}
