import { CmsComponent } from './cms.component';
import { Injectable, NgModule } from '@angular/core';
import { Resolve, RouterModule, Routes, UrlSegment, ActivatedRoute } from '@angular/router';
import { SearchComponent } from "../search/search.component";

const routes: Routes = [
  {
    matcher: (url) => {
      if (url.length > 0 && !url.some(s => s.path === 'pls-search')) {
        return {
          consumed: url,
          posParams: {
            path: new UrlSegment(url.join('%2f'), {})
          }
        };
      }
      return null;
    },
    component: CmsComponent
  },
  {
    matcher: (url) => {
      if (url.length > 0) {
        return {
          consumed: url,
          posParams: {
            path: new UrlSegment(url.join('%2f'), {})
          }
        };
      }
      return null;
    },
    component: SearchComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule { }
