import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { Media } from "../../models/cms.model";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-page-block-video',
  templateUrl: './page-block-video.component.html',
  styleUrls: ['./page-block-video.component.scss']
})
export class PageBlockVideoComponent implements OnInit {

  @Input()
  model: Media;
  closedCaptions: [string, Media][];

  get mediaUrl() {
    return environment.mediaUrl;
  }
  constructor(public cmsService: CmsService) { }

  ngOnInit(): void {
    this.getVideoConfiguration();
  }

  getVideoConfiguration() {
    this.cmsService.getMedia(this.model?.id).subscribe((resp: Media) => {
        this.closedCaptions = resp.closedCaptions;
      });
  }

}
