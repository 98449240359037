import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService } from '../cms/services/cms.service';
import { StandardPage } from '../cms/models/cms-standard-page.model';
import { Observable, Subscriber } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { PagedResult } from "../cms/models/paged-result";
import { PageEvent } from '@angular/material/paginator';
import { PageSearchResult } from "../cms/models/page-search-result.model";


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  private snapshot: ActivatedRouteSnapshot;

  results: PageSearchResult[] ;
  posts: [{ tags: any[] }];
  searchText: string;
  paginator = {
    length: +0,
    pageIndex: +0,
    pageSize: +0,
    pageSizeOptions: [5, 10, 25, 100],
    pageEventHandler: ($event: PageEvent) => {
      this.paginator.pageIndex = $event.pageIndex;
      this.paginator.pageSize = $event.pageSize;
      this.search(this.searchText, this.paginator.pageIndex, this.paginator.pageSize);
    } 
  };
  get pageIndexLength(): string {
    let start = ((this.paginator.pageIndex - 1) * this.paginator.pageSize) + 1;
    let end = (this.paginator.pageIndex * this.paginator.pageSize);
    if (this.paginator.pageSize > this.paginator.length) {
      end = this.paginator.length;
    }
    return `${start} - ${end}`;
  }
  get headerText(): string {
    return `Results for "${this.searchText ?? ' - '}"`;
  }

  constructor(private cmsService: CmsService, private route: ActivatedRoute, private router: Router) {
    this.snapshot = this.route.snapshot;
  }

  ngOnInit(): void {
    this.initSearch();
  }
  initSearch() {
    this.searchText = this.snapshot.queryParamMap.get('s');
    this.paginator.pageIndex = parseInt(this.snapshot.queryParamMap.get('pageIndex') ?? "0");
    this.paginator.pageSize = parseInt(this.snapshot.queryParamMap.get('pageSize') ?? "10");
    this.search(this.searchText, this.paginator.pageIndex, this.paginator.pageSize);
  }
  search(searchText: string, pageIndex: number, pageSize: number) {
    this.cmsService.search(this.searchText, this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe((response: PagedResult<PageSearchResult[]>) => {
        this.results = response.data;
        this.paginator.length = response.length;
        this.paginator.pageIndex = response.pageIndex;
        this.paginator.pageSize = response.pageSize;
      });
  }
}
