import { SharedModule } from './../shared/shared.module';

import { CommonModule } from '@angular/common';

import { CmsRoutingModule } from './cms-routing.module';
import { CmsComponent } from './cms.component';
import { RouterModule } from '@angular/router';
import { AppMatModule } from '../shared/app-mat.module';
import { NgModule } from '@angular/core';
import { ViewNavigationComponent } from './view-navigation/view-navigation.component';
import { CmsPageHeaderComponent } from './components/cms-page-header/cms-page-header.component';
import { CmsSecondaryHeaderComponent } from './components/cms-secondary-header/cms-secondary-header.component';
import { CmsLoginComponent } from './pages/cms-login/cms-login.component';
import { CourseLandingPageComponent } from './pages/course-landing-page/course-landing-page.component';
import { StandardPageComponent } from './pages/standard-page/standard-page.component';
import { CourseDetailPageComponent } from './pages/course-detail-page/course-detail-page.component';
import { PageBlocksComponent } from './components/page-blocks/page-blocks.component';
import { CourseCurriculumPageComponent } from './pages/course-curriculum-page/course-curriculum-page.component';
import { CmsSubNavigationComponent } from './components/cms-sub-navigation/cms-sub-navigation.component';
import { CourseStartPageComponent } from './pages/course-start-page/course-start-page.component';
import { CourseCompletionPageComponent } from './pages/course-completion-page/course-completion-page.component';
import { GettingUnderwayPageComponent } from './pages/getting-underway-page/getting-underway-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { CmsTilesComponent } from './components/cms-tiles/cms-tiles.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ComingSoonPageComponent } from './pages/coming-soon-page/coming-soon-page.component';
import { CmsVideoComponent } from './components/cms-video/cms-video.component';
import { VimeModule } from '@vime/angular';
import { PageBlockVideoComponent } from './components/page-blocks/page-block-video.component';

@NgModule({
  declarations: [
    CmsComponent,
    ViewNavigationComponent,
    CmsPageHeaderComponent,
    CmsSecondaryHeaderComponent,
    CmsLoginComponent,
    CourseLandingPageComponent,
    StandardPageComponent,
    CourseDetailPageComponent,
    PageBlocksComponent,
    CourseCurriculumPageComponent,
    CmsSubNavigationComponent,
    CourseStartPageComponent,
    CourseCompletionPageComponent,
    GettingUnderwayPageComponent,
    WelcomePageComponent,
    CmsTilesComponent,
    LandingComponent,
    ComingSoonPageComponent,
    CmsVideoComponent,
    PageBlockVideoComponent
  ],
  imports: [
    CommonModule,
    CmsRoutingModule,
    RouterModule,
    AppMatModule,
    SharedModule,
    VimeModule,
  ],
  exports: [CmsLoginComponent],
  providers: [],
})
export class CmsModule {}
