import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpDictionary } from 'src/app/shared/services/help/help';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  dictionary: HelpDictionary[];
  searchText = '';
  selectedItem: any = {};
  filteredResults: HelpDictionary[];
  canBrowse: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<any>,
    private sanitizer: DomSanitizer
  ) {
    this.canBrowse = dialogData.canBrowse;
    this.dictionary = dialogData.dictionary;
    this.selectedItem = dialogData.selectedItem;
  }

  ngOnInit(): void {
    this.filteredResults = this.dictionary;
    this.searchText = this.dialogData.selectedItem || '';
    const matches = this.dictionary.filter(
      (h) => h.label.toUpperCase() === this.searchText.toUpperCase()
    );
    this.selectedItem = matches.length > 0 ? matches[0] : {};
  }

  onSelectHelp(label: string) {
    this.selectedItem = this.dictionary.filter((h) => h.label === label)[0];
  }

  clearSearch() {
    this.searchText = '';
    this.filteredResults = this.dictionary;
  }

  filterDictionary() {
    if (this.searchText !== '') {
      const results = [];

      this.dictionary.forEach((h) => {
        if (h.label.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1) {
          results.push(h);
        }
        this.filteredResults = results;
      });
    } else {
      this.filteredResults = this.dictionary;
    }
  }

  sanitizeUrl(link: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  onClose() {
    this.dialogRef.close();
  }
}
