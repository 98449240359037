import { Component, OnInit } from '@angular/core';
import { CmsService, CmsSettings } from '../../services/cms.service';

@Component({
  selector: 'app-cms-login',
  templateUrl: './cms-login.component.html',
  styleUrls: ['./cms-login.component.scss'],
})
export class CmsLoginComponent implements OnInit {
  constructor(public readonly cmsService: CmsService) {}

  ngOnInit(): void {

  }
}
