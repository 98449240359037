import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavComponent } from "./nav.component";
import { SharedModule } from "../shared/shared.module";
import { AppMatModule } from "../shared/app-mat.module";
import { LayoutModule } from "@angular/cdk/layout";
import { RouterModule } from "@angular/router";
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { SearchModule } from "../search/search.module";



@NgModule({
  declarations: [NavComponent, MenuListItemComponent],
  imports: [
    AppMatModule,
    CommonModule,
    LayoutModule,
    RouterModule,
    SharedModule,
    SearchModule
  ],
  exports: [NavComponent],
})
export class NavModule {}
