import { Component, Input, OnInit } from '@angular/core';
import { StandardPage } from 'src/app/cms/models/cms-standard-page.model';
import { PageSearchResult } from "../../../cms/models/page-search-result.model";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-pls-search-result',
  templateUrl: './pls-search-result.component.html',
  styleUrls: ['./pls-search-result.component.scss'],
})
export class PlsSearchResultComponent implements OnInit {
  @Input() result: PageSearchResult;

  constructor() {}

  ngOnInit(): void { }
  getSearchResultLink(slug: string): string {
    return `/cms/${slug}`;
  }
  treateSearchResults(html: string): string {
    const p = document.createElement('div');
    p.innerHTML = html;
    this.removeNodes(p.getElementsByTagName('h1'));
    this.removeNodes(p.getElementsByTagName('h2'));
   
    return p.innerText.substring(0, 250) + ' ...';
  }
  removeNodes(nodes: HTMLCollectionOf<any>) {
    for (var i = 0, len = nodes.length; i != len; ++i) {
      nodes[0].parentNode.removeChild(nodes[0]);
    }
  }
}
