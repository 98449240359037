import { NavService } from 'src/app/shared/services/nav.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterContentInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatSidenavContent } from '@angular/material/sidenav';
import { Observable, timer } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { HelpService } from '../shared/services/help/help.service';
import { CmsService, CmsSettings } from '../cms/services/cms.service';
import { SiteMap } from '../cms/models/site-map.model';
import { NavItem, NavItem as INavItem } from '../shared/models/nav-item.model';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
  UrlSegment,
} from '@angular/router';
import { ViewNavigationService } from "../cms/services/view-navigation.service";
import { ViewNavigation } from "../cms/models/view-navigation.modle";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterContentInit {
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('sideNavContent') mainContent: MatSidenavContent;
  drawerOpen = true;
  isMobile: boolean;

  searchText$: BehaviorSubject<string> = new BehaviorSubject('');


  onSearchInitiated(searchValue: string) {
   
  }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  navItems: Array<NavItem> = [];

  // Get initial route (useful for direct links or refreshes)
  isCmsRoute: boolean = window.location.href.indexOf('cms') > -1;
  breadcrumb: SiteMap[];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private helpService: HelpService,
    private router: Router,
    private NavService: NavService,
    public  cmsService: CmsService,
    private viewNavigationService: ViewNavigationService
  ) {}
  ngAfterContentInit(): void {
    this.NavService.appDrawer = this.drawer;
  }

  ngOnInit() {
    this.getSiteMap();
    this.getCurrentViewNav();
    this.checkIfCmsRoute();

    this.isHandset$.subscribe((d: boolean) => {
      this.isMobile = d;

      if (d) {
        this.drawerOpen = false;
      }
    });
    this.searchText$.subscribe(s => {
      if (s && s !== '') {
        this.router.navigate(['/']).then(_ => {
          this.router.navigate(['cms', 'pls-search'], { queryParams: { s: s } });
        });
      }
    });
  }

  getCurrentViewNav(): void {
    this.viewNavigationService.currentViewNavigation()
      //.subscribe((viewNavigation: ViewNavigation) => {
      //  this.setExpandedNodes(viewNavigation.breadcrumb, this.navItems[0]);
      //});
    .subscribe((viewNavigation: ViewNavigation) => {
      if (this.navItems?.length > 0) {
        this.navItems.forEach((item: NavItem) => {
          this.setExpandedNodes(viewNavigation.breadcrumb, item);
        });
      }
    });
  }
  getSiteMap(): void {
    this.cmsService.getSiteMap().subscribe({
      next: (siteMapArr: SiteMap[]) => this.addItemsToNavItemArr(siteMapArr)
    });
  }
  private setExpandedNodes(breadcrumb: SiteMap[], currentSiteNode: NavItem): void {
    currentSiteNode.isExpanded = false;
    if (currentSiteNode?.children.length > 0) {
      currentSiteNode.children.forEach((child: NavItem) => {
        this.setExpandedNodes(breadcrumb, child);
      });
    }
    if (breadcrumb?.filter((b: SiteMap) => b.id === currentSiteNode.id).length > 0) {
      currentSiteNode.isExpanded = true;
    }
  }
  private addItemsToNavItemArr(siteMapArr: Array<SiteMap>): void {
    siteMapArr.forEach((map: SiteMap) =>
      this.navItems.push(this.getNavItems(map))
    );
  }

  checkIfCmsRoute(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('cms') > -1) this.isCmsRoute = true;
        else this.isCmsRoute = false;
      }
    });
  }

  private getNavItems(siteMap: SiteMap): INavItem {
    let navItem = <NavItem>{ children: <NavItem[]>[] };

    if (siteMap.items.length > 0) {
      siteMap.items.forEach((s) => {
        navItem.children.push(this.getNavItems(s));
      });
    }
    navItem.id = siteMap.id;
    navItem.displayName = siteMap.menuTitle;
    navItem.disabled = siteMap.isHidden;
    navItem.pageTypeName = siteMap.pageTypeName;
    navItem.route =
      siteMap.permalink === '/'
        ? 'cms/home'
        : `cms${siteMap.permalink.toLowerCase()}`;
    navItem.isExpanded = false;
    return navItem;
  }
  toggleDrawer() {
    this.drawer.toggle();
    this.drawerOpen = this.drawer.opened;
  }

  onOpenHelp() {
    const dictionary = this.helpService.getAllDictionaries();
    this.helpService.openHelpModal(dictionary, null, true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.drawer) {
      return;
    }
    timer(50)
      .pipe(take(1))
      .subscribe(() => (this.drawerOpen = this.drawer.opened));
  }
  //<mat-selection - list #list
  //  (selectionChange) = "selectionChange($event, list.selectedOptions)"
  //  (keydown.arrowup) = "selectionChange($event)"
  //  (keydown.arrowdown) = "selectionChange($event)" >

  //<mat-list - option(mouseenter)="showEditIcon(true, i)"(mouseleave) = "showEditIcon(false, i)"
  //* ngFor="let lotItem of lotList; let i = index;"
  //  (click) = "showLotDetails(lotItem, i)"
  //  [value] = 'lotItem' >
}
