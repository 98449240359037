import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
  NavigationError,
  NavigationCancel
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private loading$ = new BehaviorSubject<boolean>(false);
  private spinTillStopIsCalled = false;
  

  constructor(private router: Router) {
    this.init();
  }
  start() {
    if (!this.spinTillStopIsCalled) {
      this.spinTillStopIsCalled = true;
      this.loading$.next(true);
    }
  }
  stop() {
    if (this.spinTillStopIsCalled) {
      this.loading$.next(false);
      this.spinTillStopIsCalled = false;
    }
  }
  spinning() {
    return this.loading$;
  }
  init() {
    this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart
          || e instanceof NavigationEnd
          || e instanceof NavigationCancel
          || e instanceof NavigationError
      )
    ).subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loading$.next(true);
      } else if (!this.spinTillStopIsCalled) {
        this.loading$.next(false);
      }
    });
  }
}
