import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewNavigation } from '../models/view-navigation.modle';

@Injectable({
  providedIn: 'root'
})
export class ViewNavigationService {

  private viewNavigation$: BehaviorSubject<ViewNavigation> = new BehaviorSubject<ViewNavigation>(new ViewNavigation())
  
  constructor(private http: HttpClient) {}

  currentViewNavigation():  BehaviorSubject<ViewNavigation> {
    return this.viewNavigation$;
  }
  setViewNavigation(id: string): void {
    if (id) {
      this.http.get<ViewNavigation>(`api/sitemap/${id}/navigation`)
        .subscribe((res: ViewNavigation) => {
          this.viewNavigation$.next(res);
        });
    }
  }
}
