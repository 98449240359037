import { Component, Input, OnInit } from '@angular/core';

export interface CmsTile {
  title: string;
  icon?: string;
  link?: string;
  externalLink?: string;
}

@Component({
  selector: 'app-cms-tiles',
  templateUrl: './cms-tiles.component.html',
  styleUrls: ['./cms-tiles.component.scss'],
})
export class CmsTilesComponent implements OnInit {
  @Input() tiles: any[];
  @Input() center = false;

  constructor() {}

  ngOnInit(): void {
    
  }
}
