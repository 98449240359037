import { Component, OnInit, Input } from '@angular/core';
import { Media } from "../../models/cms.model";
import { CmsService } from "../../services/cms.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-cms-video',
  templateUrl: './cms-video.component.html',
  styleUrls: ['./cms-video.component.scss']
})
export class CmsVideoComponent implements OnInit {
  @Input('model')
  public model;
  public closedCaptions: [string, Media][];

  get displayVideoSection(): boolean {
    return !!this.backgroundImageUrl || !!this.videoSrc;
  }

  get backgroundImageUrl(): string {
    return this.model?.videoRegion?.backgroundImage?.media?.publicUrl;
  }

  get videoSrc(): string {
    return this.model?.videoRegion?.video?.media?.publicUrl;
  }

  get videoType(): string {
    return this.model?.videoRegion?.video?.media?.contentType;
  }

  get videoMedia(): Media {
    return this.model?.videoRegion?.video?.media;
  }

  get mediaUrl(): string {
    return environment.mediaUrl;
  }
  constructor(public cmsService: CmsService) { }

  ngOnInit(): void {
    this.getVideoConfiguration();
  }

  getVideoConfiguration() {
    if (this.videoMedia) {
      this.cmsService.getMedia(this.videoMedia?.id).subscribe((resp: Media) => {
        this.closedCaptions = resp.closedCaptions;
      });
    }
  }
  onPlaybackReady($event) {
    
  }
}
