import { Component, Input, OnInit } from '@angular/core';
import { SiteMap } from '../../models/site-map.model';

@Component({
  selector: 'app-standard-page',
  templateUrl: './standard-page.component.html',
  styleUrls: ['./standard-page.component.scss']
})
export class StandardPageComponent implements OnInit {

  @Input("model")
  public model;
  
  constructor() { 
    
  }

  ngOnInit(): void {
  }

}
