export class PageSearchResult {
  siteId: string;
  pageId: string;
  slug: string;
  title: string;
  content: string;
  blockCount: number;
  matchTitle: number;
  matchSlug: number;
  weightedOrder: number;
}
