<div class="pls-cms-login">
  <header class="pls-container">
    <div class="col-md-12">
      <img
        src="https://idoe-dev.azurewebsites.net/assets/img/{{
          cmsService.getCmsSpecificValue({
            iowaValue: 'iowa-logo.svg',
            coloradoValue: 'colorado-logo.svg'
          })
        }}"
        alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
          cmsService.cmsSettings.getValue()?.project
        }} Logo"
        class="logo logo--header"
      />
    </div>
  </header>

  <div class="pls-container container--login">
    <div class="col-md-12 form__container--logo">
      <img
        src="https://idoe-dev.azurewebsites.net/assets/img/{{
          cmsService.getCmsSpecificValue({
            iowaValue: 'iowa-logo.svg',
            coloradoValue: 'colorado-logo.svg'
          })
        }}"
        alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
          cmsService.cmsSettings.getValue()?.project
        }} Logo"
        class="logo logo--form"
      />
    </div>
    <div class="col-md-12">
      <form class="form" method="post">
        <div class="form__group">
          <label for="username">Username</label>
          <input type="text" id="username" />
        </div>
        <div class="form__group">
          <label for="password">Password</label>
          <input type="password" id="password" />
        </div>
        <div class="form__help">
          <p>
            Don't have an account?
            <a href="">Sign up here.</a>
          </p>

          <a href="">Forgot Password?</a>
        </div>

        <div class="form__action">
          <button class="btn">Log In</button>
        </div>
      </form>
    </div>
  </div>
</div>
