import { Component, Input, OnInit } from '@angular/core';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { Router } from '@angular/router';
import { ViewNavigationService } from '../../services/view-navigation.service';
import { SiteMap } from '../../models/site-map.model';
import { StandardPage } from '../../models/cms-standard-page.model';

@Component({
  selector: 'app-course-curriculum-page',
  templateUrl: './course-curriculum-page.component.html',
  styleUrls: ['./course-curriculum-page.component.scss'],
})
export class CourseCurriculumPageComponent implements OnInit {
  @Input()
  model: any;
  current: SiteMap = new SiteMap();

  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService
  ) {
    this.subscribeCurrentViewNavigation();
  }

  ngOnInit(): void {}

  navigateTo(url: string) {
    this.router.navigate([`/cms/${url}`]);
  }

  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
//        window.scrollTo(0, 0);
      });
  }
}
