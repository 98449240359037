<div class="pls-cms" *ngIf="results">
  <section class="pls-container">
    <h1>{{ headerText }}</h1>
    <p *ngIf="results.length">Showing {{ pageIndexLength }} of {{ paginator.length }} results</p>

    <div class="card-spacing-top">
      <app-pls-search-result-list [results]="results"
                                  [searchText]="searchText"></app-pls-search-result-list>
    </div>
    <mat-paginator [length]="paginator.length"
                   [pageSize]="paginator.pageSize"
                   [pageSizeOptions]="paginator.pageSizeOptions"
                   (page)="paginator.pageEventHandler($event)"
                   aria-label="Page serach results">
    </mat-paginator>
  </section>
</div>
