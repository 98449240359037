import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { CmsService, CmsSettings } from '../../services/cms.service';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  @Input('model') public model;
  current: SiteMap = new SiteMap();

  tiles;

  get backgroundImage(): string {
    return this.cmsService.getCmsSpecificValue({
      iowaValue: 'assets/img/background/ia-bg.png',
      coloradoValue:
        'https://images.unsplash.com/photo-1588072432836-e10032774350?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80',
    });
  }

  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService,
    public readonly cmsService: CmsService
  ) {
    this.subscribeCurrentViewNavigation();
  }

  ngOnInit(): void {
    this.getTiles();
  }

  getTiles() {
    this.tiles = [
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/iowa-logo-sm.svg',
          coloradoValue: 'assets/img/colorado-logo-sm.svg',
        }),
        link: '',
        title: `Meet ${this.cmsService.cmsSettings.getValue()?.state} ${
          this.cmsService.cmsSettings.getValue()?.project
        }`,
      },
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/icons/pls/blocks.svg',
          coloradoValue: 'assets/img/icons/pls/blocks-co.svg',
        }),
        link: '',
        title: 'Early ACCESS',
      },
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/icons/pls/swing.svg',
          coloradoValue: 'assets/img/icons/pls/swing-co.svg',
        }),
        link: '',
        title: 'Preschool Special Education',
      },
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/icons/pls/desk.svg',
          coloradoValue: 'assets/img/icons/pls/desk-co.svg',
        }),
        link: '',
        title: 'Special Education',
      },
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/icons/pls/cap.svg',
          coloradoValue: 'assets/img/icons/pls/cap-co.svg',
        }),
        link: '',
        title: 'Secondary Transition Special Education',
      },
      {
        icon: this.cmsService.getCmsSpecificValue({
          iowaValue: 'assets/img/icons/pls/group.svg',
          coloradoValue: 'assets/img/icons/pls/group-co.svg',
        }),
        link: '',
        title: 'Related Service Provider',
      },
    ];
  }

  navigateTo(url: string) {
    this.router.navigate([`/cms/${url}`]);
  }
  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
        //window.scrollTo(0, 0);
      });
  }
}
