import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageAlertComponent } from './components/page-alert/page-alert.component';
import { RouterModule } from '@angular/router';
import { AppMatModule } from './app-mat.module';
import { HelpTextSheetComponent } from './components/help/help-text-sheet/help-text-sheet.component';
import { HelpModalComponent } from './components/help/help-modal/help-modal.component';
import { HelpDirectionalTextComponent } from './components/help/help-directional-text/help-directional-text.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { AltAttributePipe } from "./pipes/alt-attr.pipe";
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';

@NgModule({
  declarations: [
    NoAccessComponent,
    NotFoundComponent,
    PageHeaderComponent,
    PageAlertComponent,
    HelpTextSheetComponent,
    HelpModalComponent,
    HelpDirectionalTextComponent,
    SafeHtmlPipe,
    ScrollIntoViewDirective,
    AltAttributePipe,
    SpinnerOverlayComponent
  ],
  imports: [CommonModule, RouterModule, AppMatModule],
  exports: [
    PageHeaderComponent,
    PageAlertComponent,
    HelpTextSheetComponent,
    HelpModalComponent,
    HelpDirectionalTextComponent,
    SafeHtmlPipe,
    AltAttributePipe,
    SpinnerOverlayComponent
  ],
})
export class SharedModule {}
