import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsLoginComponent } from './cms/pages/cms-login/cms-login.component';
import { LandingComponent } from './cms/pages/landing/landing.component';
import { NavComponent } from './nav/nav.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';


const routes: Routes = [
  {
    path: '',
    component: NavComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LandingComponent,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'cms',
        loadChildren: () =>
          import('./cms/cms.module').then((mod) => mod.CmsModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then((mod) => mod.SearchModule),
      },
    ],
  },
  {
    path: 'login/cms',
    component: CmsLoginComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '*',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
