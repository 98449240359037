import { Component, OnInit, AfterContentInit } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
  NavigationError,
  NavigationCancel
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SpinnerService } from "./shared/services/spinner.service";
import { LocalStoreManager } from "./shared/services/local-store-manager.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  loading = true;

  constructor(private spinnerService: SpinnerService, private storageManager: LocalStoreManager) {
    storageManager.initialiseStorageSyncListener();

  }

  ngOnInit(): void {
    setTimeout((c) => this.spinnerService.spinning().subscribe((b: boolean) => this.loading = b), 500);
  }
}
