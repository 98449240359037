import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { CmsService, CmsSettings } from '../../services/cms.service';
import { ViewNavigationService } from '../../services/view-navigation.service';
import { Media } from "../../models/cms.model";

@Component({
  selector: 'app-course-landing-page',
  templateUrl: './course-landing-page.component.html',
  styleUrls: ['./course-landing-page.component.scss'],
})
export class CourseLandingPageComponent implements OnInit {
  @Input('model')
  public model;

  current: SiteMap = new SiteMap();
  state: string = this.cmsService.cmsSettings.getValue()?.state;
  sandBoxTitle: string;
  sandBoxSummary: string;

  leftNotNull: boolean = true;
  centerNotNull: boolean = true;
  rightNotNull: boolean = true;
  allThreeNotNull: boolean = true;

  tryItNotNull: boolean = false;
  learnItNotNull: boolean = false;

  
  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService,
    public cmsService: CmsService
  ) {}

  ngOnInit(): void {
    this.sortNulls();
    this.getSandBox();
    this.getTryItSection();
    this.getLearnIt();
  }
  
  getSandBox() {
    if (this.state === 'Iowa') {
      this.sandBoxTitle = 'Let me practice in a sandbox.';
      this.sandBoxSummary =
        'This options allows you to try out the function you just learned in a setting that provides sample learners. Working in the sandbox will not affect your current case load.';
    }
    if (this.state === 'Colorado') {
      this.sandBoxTitle = 'Let me try it on the training site.';
      this.sandBoxSummary =
        'This options allows you to try out the function you just learned in a setting that provides sample learners. Working in the training site will not affect your current case load.';
    }
    if (this.state === '') {
      this.sandBoxTitle = 'Let me try it on the training site.';
      this.sandBoxSummary =
        'This options allows you to try out the function you just learned in a setting that provides sample learners. Working in the training site will not affect your current case load.';
    }
  }

  

  getNavigationLink(slug: string): Array<string> {
    if (slug) {
      return [`/cms/${slug}`];
    }
    return [];
  }

  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
      });
  }

  sortNulls() {
    if (
      this.model.shareItLeft.linkUrl.value === null ||
      this.model.shareItLeft.linkUrl.value === ''
    )
      this.leftNotNull = false;
    if (
      this.model.shareItCenter.linkUrl.value === null ||
      this.model.shareItCenter.linkUrl.value === ''
    )
      this.centerNotNull = false;
    if (
      this.model.shareItRight.linkUrl.value === null ||
      this.model.shareItRight.linkUrl.value === ''
    )
      this.rightNotNull = false;

    if (
      this.leftNotNull == false &&
      this.centerNotNull == false &&
      this.rightNotNull == false
    )
      this.allThreeNotNull = false;
  }

  getTryItSection(): Array<{ icon: string; heading: string; body: string }> {
    if (
      !this.cmsService.cmsSettings.getValue() ||
        !this.model.tryItDetails?.length
    ) {
      return;
    }

    const ret = [];

    this.model.tryItDetails.forEach((detail, index) =>
      ret.push({
        icon:
          index === 0
            ? this.cmsService.getCmsSpecificValue({
              iowaValue: 'sandbox.svg',
              coloradoValue: 'sandbox-alt.svg',
              })
            : index === 1
            ? this.cmsService.getCmsSpecificValue({
              iowaValue: 'iowa-logo-sm.svg',
              coloradoValue: 'colorado-logo-sm.svg',
              })
            : null,
        heading: detail.title.value,
        body: detail.summary.value,
        link: detail.pageLink.value,        
      })
    );

    if (ret.length > 0)(this.tryItNotNull = true)
   
    return ret;
  }

  getLearnIt() {
    if (this.model.courseDetails?.length > 0) {
      this.learnItNotNull = true;
    }
  }

}
