<section class="pls-video"
         style="background-image: url('{{ backgroundImageUrl }}') !important"
         [ngClass]="{
    'pls-video--no-video': !model?.videoRegion.video.hasValue
  }"
         *ngIf="displayVideoSection">
  <div class="pls-container">
    <video crossorigin="anonymous"
           controls="controls"
           controlsList="nodownload"
           class="pls-video-el"
           *ngIf="model?.videoRegion.video.hasValue">
      <source src="{{mediaUrl}}{{ videoSrc }}" type="{{ videoType }}" />
      <track *ngFor="let c of closedCaptions"
             srclang="{{c.item1}}"
             src="{{mediaUrl}}{{c.item2.publicUrl}}"
             label="English"
             kind="captions"
             default/>
      Sorry, your browser doesn't support embedded videos.
    </video>
    <!--<vm-player #player
               playsinline
               (vPlaybackReady)="onPlaybackReady()">
      <vm-video cross-origin="true"
                d-poster="https://media.vimejs.com/poster.png">
        <source data-src="{{ videoSrc }}" type="{{ videoType }}" />
        <track *ngFor="let c of closedCaptions"
               srclang="{{c.item1}}"
               src="{{c.item2.publicUrl}}"
               label="English"
               kind="captions"
               default/>
      </vm-video>

      <vm-default-ui>
        <tap-sides-to-seek></tap-sides-to-seek>
      </vm-default-ui>
    </vm-player>-->
  </div>
</section>
