import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { CmsService, CmsSettings } from '../../services/cms.service';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'app-getting-underway-page',
  templateUrl: './getting-underway-page.component.html',
  styleUrls: ['./getting-underway-page.component.scss'],
})
export class GettingUnderwayPageComponent implements OnInit {
  @Input('model')
  public model;

  current: SiteMap = new SiteMap();

  stepTiles: any[];
  tiles: any[];

  state: string = this.cmsService.cmsSettings.getValue()?.state;
  headerTextTop: string;
  headerTextMiddle: string;
  headerTextBottom: string;

  coloradoDropSection: boolean = false;

  get backgroundImage(): string {
    return this.cmsService.getCmsSpecificValue({
      iowaValue: 'assets/img/background/ia-bg.png',
      coloradoValue:
        'https://images.unsplash.com/photo-1588072432836-e10032774350?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80',
    });
  }

  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService,
    public readonly cmsService: CmsService
  ) {
    this.subscribeCurrentViewNavigation();
  }
  ngOnInit(): void {
    this.matchStepLinks();
    this.matchLinks();
    this.getSandBox();
  }

  matchStepLinks(): void {
    if (!this.model || !this.model?.gettingUnderwayButtonLinks) return;

    const links = this.model.gettingUnderwayButtonLinks;

    const GettingUnderway1 = links.gettingUnderway1.hasValue && {
      icon: this.cmsService.getCmsSpecificValue({
        iowaValue: 'assets/img/iowa-logo-sm.svg',
        coloradoValue: 'assets/img/colorado-logo-sm.svg',
      }),
      link: links.gettingUnderway1.page.permalink,
      title: links.buttonTextGettingUnderway1.value,
    };

    this.stepTiles = [GettingUnderway1];
  }

  matchLinks(): void {
    if (!this.model || !this.model?.gettingUnderwayButtonLinks) return;

    const links = this.model.gettingUnderwayButtonLinks;
    const GettingUnderway2 = links.gettingUnderway2.hasValue && {
      icon: this.cmsService.getCmsSpecificValue({
        iowaValue: 'assets/img/icons/pls/ea-tile.png',
        coloradoValue: 'assets/img/icons/pls/blocks-co.svg',
      }),
      link: links.gettingUnderway2.page.permalink,
      title: links.buttonTextGettingUnderway2.value,
    };
    const GettingUnderway3 = links.gettingUnderway3.hasValue && {
      icon: this.cmsService.getCmsSpecificValue({
        iowaValue: 'assets/img/icons/pls/special-education-tile.png',
        coloradoValue: 'assets/img/icons/pls/desk-co.svg',
      }),
      link: links.gettingUnderway3.page.permalink,
      title: links.buttonTextGettingUnderway3.value,
    };
    const GettingUnderway4 = links.gettingUnderway4.hasValue && {
      icon: this.cmsService.getCmsSpecificValue({
        iowaValue: 'assets/img/icons/pls/operations-tile.png',
        coloradoValue: 'assets/img/icons/pls/cap-co.svg',
      }),
      link: links.gettingUnderway4.page.permalink,
      title: links.buttonTextGettingUnderway4.value,
    };

    this.tiles = [GettingUnderway2, GettingUnderway3, GettingUnderway4];
  }

  navigateTo(url: string) {
    this.router.navigate([`/cms/${url}`]);
  }
  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
//        window.scrollTo(0, 0);
      });
  }

  getSandBox() {
    if (this.state === 'Iowa') {
      this.headerTextTop =
        'Educators throughout the state of Iowa are working together to ensure every learner in Iowa receives the services they need to achieve an optimal learning experience.';
      this.headerTextMiddle =
        'Each member within the PLS system is assigned a series of tasks based on their role.If you wear multiple hats, you may have access to multiple mini - modules!';
      this.headerTextBottom =
        'Once you complete the Meet Iowa PLS component, you can access the next component.';
    }
    if (this.state === 'Colorado') {
      this.headerTextTop =
        'The Ascend Colorado PLS (Professional Learning System) helps users quickly learn key features of the system to successfully complete the child find and IEP processes. ';
      this.headerTextMiddle =
        'The PLS is an interactive tool where users choose the topic(s) that will help answer their current questions and provide links to access training and direct user support.';
      this.headerTextBottom = '';
      this.coloradoDropSection = true;
    }
    if (this.state === '') {
      this.headerTextTop =
        'Educators throughout the state are working together to ensure every learner receives the services they need to achieve an optimal learning experience.';
      this.headerTextMiddle =
        'Each member within the PLS system is assigned a series of tasks based on their role.If you wear multiple hats, you may have access to multiple mini - modules!';
      this.headerTextBottom =
        'Once you complete the Meet PLS component, you can access the next component.';
    }
  }
}
