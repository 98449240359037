<mat-card
  class="page-header"
  [ngClass]="{
    'background--removed': backgroundColor === 'none',
    'background--red': backgroundColor === 'red',
    'background--blue': backgroundColor === 'blue',
    'background--blue-dark': backgroundColor === 'blueDark',
    'background--purple': backgroundColor === 'purple',
    'background--yellow': backgroundColor === 'yellow',
    'page-header--condensed': condensed === true
  }"
>
  <h1
    [ngClass]="{
      'text-danger': pageTitleColor === 'red',
      'mat-display-1': isBold,
      'text-normal text-lg': !isBold
    }"
  >
    {{ pageTitle }}
  </h1>

  <div class="page-header__nav hide-print">
    <ng-content select="[pageActions]"></ng-content>

    <button
      mat-flat-button
      (click)="onHelpToggled($event)"
      color="accent"
      class="mat-elevation-z0"
      *ngIf="helpLinks"
    >
      Help Links
    </button>

    <button
      mat-flat-button
      (click)="onNavBackToggled($event)"
      class="background-color--white mat-elevation-z0"
      *ngIf="navBack"
    >
      <mat-icon>arrow_back</mat-icon>
      Go Back
    </button>
    <button
      *ngIf="showSubmitBtn"
      [disabled]="submitDisabled"
      (click)="onSubmitToggled()"
      color="primary"
      mat-raised-button
    >
      <mat-icon>save</mat-icon>
      Submit
    </button>
  </div>
</mat-card>
