<div
  *ngIf="tiles?.length"
  class=""
  [ngClass]="{
    'row pls__tiles-row': !center,
    'display-flex align-items-center justify-content-center flex-wrap': center
  }"
>
  <div
    [ngClass]="{ 'col-xs-12 col-sm-4 col-xl-4': !center }"
    *ngFor="let tile of tiles"
  >
    <a
      *ngIf="tile?.link"
      [routerLink]="tile?.link && '/cms' + tile?.link"
      class="pls-card-link"
    >
      <ng-container
        *ngTemplateOutlet="tileContent; context: { tile: tile }"
      ></ng-container>
    </a>

    <a
      *ngIf="tile?.externalLink"
      [href]="tile?.externalLink"
      target="_blank"
      class="pls-card-link"
    >
      <ng-container
        *ngTemplateOutlet="tileContent; context: { tile: tile }"
      ></ng-container>
    </a>
  </div>
</div>

<ng-template #tileContent let-tile="tile">
  <div class="pls__tile" [ngClass]="{ 'pls__tile--center': center }">
    <img [src]="tile?.icon" alt="{{ tile?.title }} icon" *ngIf="tile?.icon" />
    <h3>{{ tile?.title }}</h3>
  </div>
</ng-template>
