<mat-nav-list>
  <a
    mat-list-item
    *ngFor="let help of data.help"
    class="display-block"
    [disableRipple]="true"
  >
    <span mat-line class="heading" *ngIf="help.question">{{
      help.question
    }}</span>
    <span
      mat-line
      class="body"
      *ngIf="help.answer && checkIfArray(help.answer) === false"
      >{{ help.answer }}</span
    >
    <ng-container *ngIf="checkIfArray(help.answer)">
      <div mat-line class="body">
        <ul class="my-0">
          <li *ngFor="let answer of help.answer">{{ answer }}</li>
        </ul>
      </div>
    </ng-container>
  </a>
</mat-nav-list>
