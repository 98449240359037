<div class="pls-cms">
  <section #scrollToElTarget></section>
  <show-me *ngIf="model">
    <show-page *ngIf="model.typeId === 'CourseLandingPage'">
      <app-course-landing-page [model]="model"></app-course-landing-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'CourseStartPage'">
      <app-course-start-page [model]="model"></app-course-start-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'CourseDetailPage'">
      <app-course-detail-page [model]="model"></app-course-detail-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'StandardPage'">
      <app-standard-page [model]="model"></app-standard-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'CourseCurriculumPage'">
      <app-course-curriculum-page [model]="model"></app-course-curriculum-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'CourseCompletionPage'">
      <app-course-completion-page [model]="model"></app-course-completion-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'GettingUnderwayPage'">
      <app-getting-underway-page [model]="model"></app-getting-underway-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'WelcomePage'">
      <app-welcome-page [model]="model"></app-welcome-page>
    </show-page>
    <show-page *ngIf="model.typeId === 'ComingSoon'">
      <app-coming-soon-page></app-coming-soon-page>

    </show-page>
  </show-me>
</div>
