import { Component, Input, OnInit, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-blocks',
  templateUrl: './page-blocks.component.html',
  styleUrls: ['./page-blocks.component.scss'],
})
export class PageBlocksComponent implements OnInit {
  @Input('model') public model;
  @Input() needsHeightAdjustment = true;

  uRL: string;

  constructor(private domSanitizer: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    /*console.log(this.model.blocks);*/
  }
  hasInnerText(html: string): boolean {
    const p = document.createElement('div');
    p.innerHTML = html;
    if (p) {
      return p.innerText !== '';
    }
  }
  sanitizeURL(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  treateHrefs(html: string): string {
    const p = document.createElement('div');
    p.innerHTML = html;
    let els = p.getElementsByTagName('a');
    for (let i = 0; i < els.length; i++) {
      let el = els[i];
      let href = el.getAttribute('href') as string;
      if (href && href.indexOf('//') === -1) {
        let normalizedHref = href;
        if (href.slice(0, 1) !== '/') {
          normalizedHref = '/' + href;
        };
        let segments = href.split('/');
        if (normalizedHref.split('/').indexOf('cms') < 2) {
          segments.unshift('cms');
          segments = segments.filter((s) => s !== '');
          el.setAttribute('href', segments.join('/'));
        }
        if (normalizedHref.split('/').indexOf('uploads') != 0) {
          el.setAttribute('href', normalizedHref);
        }
      }
    }    
    return p.innerHTML;
  }
  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      let href = event.target.getAttribute('href') as string;
      if (href && href.indexOf('//') === -1) {
        let normalizedHref = href;
        if (href.slice(0, 1) !== '/') {
          normalizedHref = '/' + href;
        };
        if (normalizedHref.split('/').indexOf('cms') == 1) {
          this.router.navigate([href]);
          event.preventDefault();
        }
      }
    } else {
      return;
    }
  }
}

//!!breadcrumb?.some((b: SiteMap) => b.id === currentSiteNode.id);
