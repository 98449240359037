<div
  class="view-navigation"
  [ngClass]="{ 'view-navigation--centered': isCompletion }"
  role="navigation"
  aria-label="View Navigation"
>
  <button
    tabindex="0"
    mat-raised-button
    [color]="isCompletion ? 'accent' : 'primary'"
    [disabled]="!this.previous"
    (click)="navigateTo(this.previous)"
    class="mr-2"
  >
    <mat-icon arial-label="Side nav toggle icon">keyboard_arrow_left</mat-icon>
  </button>
  <button
    tabindex="0"
    mat-raised-button 
    [color]="isCompletion ? 'accent' : 'primary'"
    [disabled]="!this.parent"
    (click)="navigateTo(this.parent)"
    class="mr-2"
  >
    <mat-icon arial-label="Side nav toggle icon">keyboard_arrow_up</mat-icon>
  </button>
  <button
    tabindex="0"
    mat-raised-button
    [color]="isCompletion ? 'accent' : 'primary'"
    [disabled]="!this.next"
    (click)="navigateTo(this.next)"
  >
    <mat-icon arial-label="Side nav toggle icon">keyboard_arrow_right</mat-icon>
  </button>
</div>
