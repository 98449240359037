import { AuthorityService } from './shared/services/authority.service';
import { Injectable } from '@angular/core';
import { DBkeys } from './shared/services/db-keys';
import { environment } from 'src/environments/environment';
import { LocalStoreManager } from './shared/services/local-store-manager.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber, of } from 'rxjs';
import { AuthUser } from './shared/models/auth-user';
import { mergeMap } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CmsService, CmsSettings } from "./cms/services/cms.service";



@Injectable()
export class AppLoadService {
  private token;
  private appName;

  constructor(private cmsService: CmsService, private authorityService: AuthorityService, private router: Router, private localStorage: LocalStoreManager) {
    this.initializeParameters();
  }
  private initializeParameters() {
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] } as any);
      this.token = httpParams.get("token");
      this.appName = httpParams.get("app");
    }
  }
  async initializeApp(): Promise<void> {
    try {
      if (this.appName && this.token) {
        await this.validateToken();
      } else {
        await this.hasSession();
      }
    }
    catch (ex) {
      this.router.navigate(['no-access']);
      console.log(ex);
    }
  }

  getSettings(): Promise<void> {
    return this.cmsService.getCmsSettings()
      .pipe(mergeMap((response: CmsSettings, index: number) => {
        let startPage = response.startPage;
        this.cmsService.updateCmsSettings(response);
        this.localStorage.saveSessionData(startPage, 'pls_start_page');
        return of(null);
      })).toPromise();
  }

  private async hasSession(): Promise<AuthUser> {
    return await this.authorityService.hasSession().toPromise();
  }

  private async validateToken(): Promise<AuthUser> {
    return await this.authorityService.authorize(this.appName, this.token).toPromise();
  }
}

