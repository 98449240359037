





<div class="display-flex align-items-center justify-content-between">
  <mat-spinner></mat-spinner>
</div>
<div class="display-flex align-items-center justify-content-between">
  <h3>Welcome, glad you're here. Let's get underway!</h3>
</div>
<!--<div class="display-flex align-items-center justify-content-between">
  <span class="my-0"><button mat-raised-button color="primary" (click)=getStartPage()>Continue</button></span>
</div>-->
