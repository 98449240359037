<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>

<section class="pls-secondary">
  <div class="pls-container">
    <app-page-blocks [model]="model"></app-page-blocks>
  </div>
</section>
<section class="pls-secondary">
  <div class="pls-container">
    <app-view-navigation></app-view-navigation>
  </div>
</section>
