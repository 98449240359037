<main class="pls pls">
  <app-cms-page-header pageTitle="Coming Soon!"></app-cms-page-header>
  <section class="pls__hero pls__hero--no-overlay">
    <section class="not-found">
      <div class="not-found__container">
        <main class="not-found__content">
          <div class="display-flex flex-column justify-content-between align-items-center">
            <section class="not-found__header">
              <h1 class="mb-0">Currently this page is in draft.</h1>
            </section>

            <section class="not-found__info">
              <h2 class="mt-0">
                Sorry, but it looks like you have landed on a page that isn't ready to share... Come back soon!
              </h2>
              <button color="primary"
                      mat-flat-button
                      class="not-found__cta"
                      [routerLink]="['/']"
                      aria-label="go home">
                Go Home
              </button>
            </section>
          </div>
        </main>
      </div>
    </section>

  </section>
</main>
