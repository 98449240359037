import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {  FormControl } from '@angular/forms'
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  //<!--https://stackblitz.com/edit/xp-search-bar?file=src%2Fapp%2Fapp.component.html
  
  //@Output("searchInitiated")
  //searchInitiated: EventEmitter<string> = new EventEmitter();

  @Input("searchText")
  searchText$: BehaviorSubject<string>;

  inputFormControl = new FormControl();

  constructor() { }

  ngOnInit() {
  }
  close() {
    this.inputFormControl.setValue('');
  }
  onSearchSubmit() {
    this.searchText$.next(this.inputFormControl.value);
    this.inputFormControl.setValue('');
  }
  onBlur() {
  }
}
