import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'app-course-completion-page',
  templateUrl: './course-completion-page.component.html',
  styleUrls: ['./course-completion-page.component.scss'],
})
export class CourseCompletionPageComponent implements OnInit {
  @Input('model')
  public model;

  get summaryText(): string {
    return this.model.courseCompletionSummary.pageExcerpt.value;
  }

  current: SiteMap = new SiteMap();

  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService
  ) {
    this.subscribeCurrentViewNavigation();
  }

  ngOnInit(): void {
    //console.log(this.model);
  }

  navigateTo(url: string) {
    this.router.navigate([`/cms/${url}`]);
  }
  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
//        window.scrollTo(0, 0);
      });
  }
}
