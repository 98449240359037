import { SiteMap } from './models/site-map.model';
import { CmsService } from './services/cms.service';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StandardPage } from './models/cms-standard-page.model';
import { map, filter} from 'rxjs/operators';
import { ViewNavigation } from './models/view-navigation.modle';
import { lastValueFrom } from '../app.pollyfills'
import { ViewNavigationService } from './services/view-navigation.service';
import { AfterViewChecked } from '@angular/core';
import { SpinnerService } from "../shared/services/spinner.service";


@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CmsComponent implements OnInit {
  model: StandardPage;
  @ViewChild("scrollToElTarget") scrollToElTarget: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private cmsService: CmsService,
    private viewNavigationService: ViewNavigationService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    
  }
  ngOnInit(): void {
      this.route.url
      .pipe(map(segments => segments.join('%2f')))
        .subscribe((url: string) => this.handle(url));

      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(_ => this.scrollToElTarget.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }));
  }
  async handle(url: string) {
    try {
      this.model = await lastValueFrom(this.cmsService.getStandardPage(url));
      if (!this.model) {
        this.router.navigate(['not-found']);
      }
      this.viewNavigationService.setViewNavigation(this.model.id);
      if (!this.model.isPublished) {
        this.model.typeId = 'ComingSoon';
      }
    } catch (ex) {
      throw ex;
    }
  }
}
