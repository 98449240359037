<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>

<app-cms-video [model]="model"></app-cms-video>

<section *ngIf="model?.pageSummaryRegion?.textSummary?.value || model.excerpt">
  <div class="pls-container">
    {{
      model?.pageSummaryRegion?.textSummary?.value
        ? model?.pageSummaryRegion?.textSummary?.value
        : model.excerpt
    }}
  </div>
</section>

<app-page-blocks
  [model]="model"
  [needsHeightAdjustment]="false"
></app-page-blocks>

<section class="py-3" *ngIf="learnItNotNull">
  <div class="pls-container">
    <h3 class="pls-section-heading">
      <img
        src="/assets/img/icons/pls/{{
          cmsService.getCmsSpecificValue({
            iowaValue: 'learn.png',
            coloradoValue: 'learn-co.png'
          })
        }}"
        alt="Learn It! Icon"
        class="pls-heading-icon"
      />
      Learn It!
    </h3>
    <div class="pls-card-row">
      <a
        *ngFor="let item of model.courseDetails"
        class="pls-card-link"
        [routerLink]="getNavigationLink(item.pageLink.page?.slug)"
      >
        <div class="pls-card">
          <h4>
            {{
              item?.pageText?.value
                ? item?.pageText?.value
                : item?.pageLink?.page?.title
            }}
          </h4>
          <p>
            {{
              item?.pageExcerpt?.value
                ? item?.pageExcerpt?.value
                : item?.pageLink?.page?.excerpt
            }}
          </p>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="pls-primary" *ngIf="tryItNotNull">
  <div class="pls-container">
    <h3 class="pls-section-heading">
      <img
        src="/assets/img/icons/pls/{{
          cmsService.getCmsSpecificValue({
            iowaValue: 'mouse.png',
            coloradoValue: 'mouse-co.png'
          })
        }}"
        alt="Try It! Icon"
        class="pls-heading-icon"
      />
      Try It!
    </h3>

    <div class="pls-card-row pls-card-row-dual">
      <ng-container *ngFor="let item of getTryItSection()">
        <a class="pls-card-link" role="link" [href]="item.link" target="_blank">
          <div class="pls-card pls-card-icon">
            <img
              *ngIf="item.icon"
              src="assets/img/{{ item.icon }}"
              alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
                cmsService.cmsSettings.getValue()?.project
              }} Logo"
            />
            <div>
              <h4>
                {{ item.heading }}
              </h4>
              <p>{{ item.body }}</p>
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</section>

<section class="pls-secondary">
  <div class="pls-container" *ngIf="allThreeNotNull">
    <h3 class="pls-section-heading">
      <img
        src="/assets/img/icons/pls/{{
          cmsService.getCmsSpecificValue({
            iowaValue: 'share.png',
            coloradoValue: 'share-co.png'
          })
        }}"
        alt="Share It! Icon"
        class="pls-heading-icon"
      />
      Share It!
    </h3>

    <div class="pls-card-row">
      <a
        [href]="model.shareItLeft.linkUrl.value"
        target="_blank"
        class="pls-card-link"
      >
        <div class="pls-card" *ngIf="leftNotNull">
          <div class="pls-container">
            <h4>{{ model.shareItLeft.linkText.value }}</h4>
            <p>
              {{ model.shareItLeft.summaryText.value }}
            </p>
          </div>
        </div>
      </a>

      <a
        [href]="model.shareItCenter.linkUrl.value"
        target="_blank"
        class="pls-card-link"
      >
        <div class="pls-card" *ngIf="centerNotNull">
          <div class="pls-container">
            <h4>{{ model.shareItCenter.linkText.value }}</h4>
            <p>
              {{ model.shareItCenter.summaryText.value }}
            </p>
          </div>
        </div>
      </a>

      <a
        [href]="model.shareItRight.linkUrl.value"
        target="_blank"
        class="pls-card-link"
      >
        <div class="pls-card" *ngIf="rightNotNull">
          <div class="pls-container">
            <h4>{{ model.shareItRight.linkText.value }}</h4>
            <p>
              {{ model.shareItRight.summaryText.value }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="pls-secondary">
  <div class="pls-container">
    <app-view-navigation></app-view-navigation>
  </div>
</section>
