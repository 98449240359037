<!--basically: if button 1 text/url not null - draw button 1 and so on-->
<!--It will be fine if you only want to use only the slug instead of the url.  Let me know and I'll redo it asap-->

<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>
<app-cms-secondary-header></app-cms-secondary-header>
<app-cms-video [model]="model"></app-cms-video>

<section>
  <app-page-blocks
    [model]="model"
    [needsHeightAdjustment]="false"
    class="card-spacing-bottom display-block"
  ></app-page-blocks>
</section>

<section>
  <div class="pls-container">
    <h3 class="pls-section-heading mb-2">Select a topic to learn more</h3>
    <app-cms-tiles [tiles]="tiles"></app-cms-tiles>
  </div>
</section>

<section class="pls-secondary">
  <div class="pls-container">
    <app-view-navigation></app-view-navigation>
  </div>
</section>
