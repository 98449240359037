<mat-sidenav-container
  class="sidenav-container"
  [ngClass]="{ 'cms-page': isCmsRoute }"
>
  <mat-sidenav
    #drawer
    class="sidenav"
    aria-label="Main"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    [ngClass]="{ 'sidenav--cms': isCmsRoute }"
  >
    <mat-toolbar class="sidenav__brand">
      <a [routerLink]="['/']">
        <img
          src="../../assets/img/{{
            cmsService.getCmsSpecificValue({
              iowaValue: 'iowa-logo.svg',
              coloradoValue: 'colorado-logo.svg'
            })
          }}"
          alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
            cmsService.cmsSettings.getValue()?.project
          }} Logo"
          class="sidenav__logo"
        />
      </a>
    </mat-toolbar>

    <mat-nav-list aria-label="Main2">
      <div class="contain-list">
        <!--<mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                <span
                  [routerLink]="['/']"
                  routerLinkActive="route__indicator--active"
                  [routerLinkActiveOptions]="{ exact: false }"
                ></span>
                Dashboard
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>-->
        <app-menu-list-item
          *ngFor="let item of this.navItems"
          [item]="item"
          [depth]="0"
          tabindex="0"
        ></app-menu-list-item>
        <!--<mat-accordion class="user__options--sidenav">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                Account
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a mat-list-item href="javascript:void(0)"> Sign Out </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>-->
        <!--<div class="bottom-buttons">
          <button
            mat-raised-button
            aria-label="Click to View Help Information"
            (click)="onOpenHelp()"
          >
            Help
          </button>
        </div>-->
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content #sideNavContent class="content mat-typography">
    <mat-toolbar class="nav--top" [ngClass]="{ 'mb-0 nav--cms': isCmsRoute }">
      <div class="app-title" #topNav>
        <ng-container>
          <button type="button"
                  aria-label="Toggle sidenav"
                  mat-icon-button
                  (click)="drawer.toggle()"
                  *ngIf="isHandset$ | async">
            <mat-icon arial-label="Side nav toggle icon" *ngIf="!drawerOpen">keyboard_arrow_right</mat-icon>
            <mat-icon arial-label="Side nav toggle icon" *ngIf="drawerOpen">keyboard_arrow_left</mat-icon>
          </button>
          <a *ngIf="isHandset$ | async" class="app-title" [routerLink]="['/']">
            <img src="../../assets/img/{{
                cmsService.getCmsSpecificValue({
                  iowaValue: 'iowa-logo-sm.svg',
                  coloradoValue: 'colorado-logo-sm.svg'
                })
              }}"
                 alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
                cmsService.cmsSettings.getValue()?.project
              }}  Logo"
                 class="sidenav__logo"
                 [ngClass]="{
                'sidenav__logo--cms': isCmsRoute,
                'pl-3': !isCmsRoute
              }" />
          </a>

          <button type="button"
                  aria-label="Toggle Sidenav"
                  mat-icon-button
                  (click)="toggleDrawer()"
                  *ngIf="!isMobile">
            <mat-icon arial-label="Side nav toggle icon" *ngIf="!drawerOpen">keyboard_arrow_right</mat-icon>
            <mat-icon arial-label="Side nav toggle icon" *ngIf="drawerOpen">keyboard_arrow_left</mat-icon>
          </button>
          <a *ngIf="!isMobile && !drawerOpen"
             class="app-title"
             [routerLink]="['/']">
            <img src="../../assets/img/{{
                cmsService.getCmsSpecificValue({
                  iowaValue: 'iowa-logo.svg',
                  coloradoValue: 'colorado-logo.svg'
                })
              }}"
                 alt="{{ cmsService.cmsSettings.getValue()?.state }} {{
                cmsService.cmsSettings.getValue()?.project
              }} Logo"
                 class="sidenav__logo"
                 [ngClass]="{
                'sidenav__logo--cms': isCmsRoute,
                'pl-3': !isCmsRoute
              }" />
          </a>
        </ng-container>
      </div>

      <span class="fill-remaining-space"></span>
      <app-search-bar (searchInitiated)="onSearchInitiated" [searchText]="searchText$">
        <mat-autocomplete>
          <mat-option *ngFor="let option of ['apple', 'orange']" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </app-search-bar>
      <!--
    <div class="user__options">
      <span class="user"> Your Name </span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="User Actions Menu"
      >
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <a mat-menu-item> Settings </a>
        <button mat-menu-item>Sign Out</button>
      </mat-menu>
    </div>
      -->
    </mat-toolbar>
    <div
      [ngClass]="{
        'main-content mat-horizontal-content-container': !isCmsRoute
      }"
    >
      <img
        alt=""
        class="img--top bg-img"
        draggable="false"
        src="../../assets/img/background/BG.svg"
      />
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
