import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cms-sub-navigation',
  templateUrl: './cms-sub-navigation.component.html',
  styleUrls: ['./cms-sub-navigation.component.scss']
})
export class CmsSubNavigationComponent implements OnInit {
  @Input() subTitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
