<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>
<app-cms-secondary-header></app-cms-secondary-header>

<app-cms-video [model]="model"></app-cms-video>
<section class="pls-secondary" *ngIf="displayLearnItSection">
  <h3 class="pls-section-heading">
    <img
      src="/assets/img/icons/pls/{{
        cmsService.getCmsSpecificValue({
          iowaValue: 'learn.png',
          coloradoValue: 'learn-co.png'
        })
      }}"
      alt="Learn It! Icon"
      class="pls-heading-icon"
    />
    Learn It!
  </h3>

  <h4 class="pls-heading-tertiary">
    {{ learnItSectionTitle }}
  </h4>
  <div [innerHTML]="learnItSectionBody"></div>
</section>

<section *ngIf="model.blocks?.length > 0">
  <app-page-blocks [model]="model"></app-page-blocks>
</section>

<section class="pls-secondary">
  <div class="pls-container">
    <app-view-navigation></app-view-navigation>
  </div>
</section>
