import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NavService } from '../../shared/services/nav.service';
import { NavItem } from 'src/app/shared/models/nav-item.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit {
  @HostBinding('attr.aria-expanded') ariaExpanded = false;
  @Input() item: NavItem;
  @Input() depth: number;


  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
  }

  onItemSelected(item: NavItem, e?: Event) {
    if (e) e.stopPropagation();

    this.router.navigate([item.route]);
  }

  isLinkActive() {
    let charPos = this.router.url.indexOf('?');
    let cleanUrl =
      charPos !== -1 ? this.router.url.slice(0, charPos) : this.router.url;
    let isCurrentLinkActive = (
      cleanUrl.replace(/(^\/)|(\/$)/, '') ===
        this.item.route.replace(/(^\/)|(\/$)/, '')
    );
    return isCurrentLinkActive;
  }
  getChildDepth(): number {
    return this.depth+1;
  }
  isExpanded(): boolean {
    return this.item.isExpanded || this.depth === 0;
  }
}
