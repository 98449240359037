<video  crossorigin="anonymous"
        controls="controls"
        controlsList="nodownload"
        class="pls-video-el pls-video-el2">
  <source src="{{mediaUrl}}{{model.publicUrl}}" />
  <track *ngFor="let c of closedCaptions"
         srclang="{{c.item1}}"
         src="{{mediaUrl}}{{c.item2.publicUrl}}"
         label="English"
         kind="captions"
         default />
  Sorry, your browser doesn't support embedded videos.
</video>
