<h1 mat-dialog-title>
  <div class="display-flex align-items-center justify-content-between">
    <span>Help</span>
    <button mat-icon-button (click)="onClose()">
      <mat-icon class="cursor-pointer">close</mat-icon>
    </button>
  </div>
</h1>

<div mat-dialog-content>
  <div class="help">
    <aside class="help__search" *ngIf="canBrowse">
      <div class="help__search-input">
        <mat-form-field class="w-100">
          <mat-label>Search for help</mat-label>
          <input
            matInput
            placeholder="search term.."
            cdkFocusInitial
            [(ngModel)]="searchText"
            (keyup)="filterDictionary()"
          />

          <span matSuffix [hidden]="searchText.length === 0">
            <button
              type="button"
              mat-icon-button
              (click)="clearSearch()"
              color="primary"
            >
              <mat-icon>close</mat-icon>
            </button>
          </span>
        </mat-form-field>
      </div>

      <ul class="help__search-list">
        <ng-container *ngFor="let help of filteredResults">
          <ng-container *ngIf="filteredResults.length > 0">
            <li
              class="help__search-item"
              [ngClass]="{
                'help__search-item--selected': help.label === selectedItem.label
              }"
              (click)="onSelectHelp(help.label)"
            >
              {{ help.label }}
            </li>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="filteredResults.length <= 0">
          <li class="help__search-item help__search-item--none">
            No Results...
          </li>
        </ng-container>
      </ul>
    </aside>
    <div class="help__result">
      <mat-card *ngIf="(selectedItem | json) != '{}'">
        <h3 class="help__result-title">{{ selectedItem.label }}</h3>
        <ng-container *ngIf="selectedItem.descriptor">
          <div [innerHTML]="selectedItem.descriptor"></div>
        </ng-container>
        <ng-container *ngIf="selectedItem.iframeSource">
          <iframe
            [src]="sanitizeUrl(selectedItem.iframeSource)"
            frameborder="0"
            class="iframe"
          ></iframe>
        </ng-container>
      </mat-card>

      <mat-card *ngIf="(selectedItem | json) == '{}'">
        <h3 class="help__result-title">Try searching for something!</h3>
        <div><p>There's no content here..</p></div>
      </mat-card>
    </div>
  </div>
</div>

<div
  mat-dialog-actions
  class="display-flex justify-content-end align-items-center"
>
  <div class="display-flex my-2">
    <button mat-button class="background-color--muted" (click)="onClose()">
      Close
    </button>
  </div>
</div>
