
import { Component, Input, OnInit } from '@angular/core';
import { ViewNavigationService } from '../../services/view-navigation.service';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { SiteMap } from '../../models/site-map.model';

@Component({
  selector: 'app-cms-secondary-header',
  templateUrl: './cms-secondary-header.component.html',
  styleUrls: ['./cms-secondary-header.component.scss'],
})
export class CmsSecondaryHeaderComponent implements OnInit {
  
  @Input() 
  subTitle: string;
  breadcrumb: Array<SiteMap>;
  
  constructor(private viewNavigationService: ViewNavigationService) { 
    this.subscribeCurrentViewNavigation(); 
  }

  ngOnInit(): void {}

  subscribeCurrentViewNavigation() {
    this.viewNavigationService.currentViewNavigation().subscribe((nav: ViewNavigation) =>{
      this.breadcrumb = nav.breadcrumb;
    });
  }
}
