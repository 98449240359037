<ng-container *ngIf="alert?.status && alert?.message">
  <div
    class="alert"
    [ngClass]="{
      'alert--info': alert.status === 'info',
      'alert--warning': alert.status === 'warning',
      'alert--success': alert.status === 'success'
    }"
  >
    <div class="alert__icon">
      <i class="fas fa-info-circle" *ngIf="alert.status === 'info'"></i>
      <i class="fas fa-exclamation" *ngIf="alert.status === 'warning'"></i>
    </div>
    <div class="alert__message">
      <p class="my-0">{{ alert.message }}</p>
    </div>
  </div>
</ng-container>
