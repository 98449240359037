<main class="pls pls">
  <app-cms-page-header pageTitle="Welcome"></app-cms-page-header>
  <section
    class="pls__hero pls__hero--lg"
    style="background-image: url('{{ backgroundImage }}') !important"
    [ngClass]="{
      'pls__hero--no-overlay':
        cmsService?.cmsSettings?.getValue()?.state === 'Iowa'
    }"
  >
    <div class="pls-container">
      <div class="welcome__logo">
        <img
          src="assets/img/{{
            cmsService.getCmsSpecificValue({
              iowaValue: 'iowa-logo.svg',
              coloradoValue: 'colorado-logo.svg'
            })
          }}"
          alt="{{ cmsService.cmsSettings?.getValue()?.state }} Logo"
        />
      </div>
    </div>
  </section>

  <div *ngIf="!coloradoDropSection">
    <div class="pls__step">
      <div class="pls-container">
        <div class="pls__step-container">
          <div class="pls__step-content">
            <h3>
              Welcome to the Professional Learning System (PLS) for
              {{
                cmsService.getCmsSpecificValue({
                  iowaValue: 'ACHIEVE',
                  coloradoValue: 'Ascend'
                })
              }}
            </h3>
            <p>
              The PLS will help you learn how to use the features of
              {{ cmsService.cmsSettings.getValue()?.project }}. It is organized
              into sections. Each section contains topical mini-modules that
              include videos, written descriptions, and opportunities to
              practice the ACHIEVE functions addressed in the mini-module.
            </p>
            <p>
              Once you complete the Meet
              {{ cmsService.cmsSettings.getValue()?.state }}
              {{ cmsService.cmsSettings.getValue()?.project }} section to learn
              the basics of navigating
              {{ cmsService.cmsSettings.getValue()?.project }}, you can then
              choose to explore any of the additional topics addressed in the
              PLS that you would like to learn more about
            </p>
            <p>
              There are some section in the PLS that are still in development, those sections are coming soon.
            </p>
          </div>
          <app-cms-tiles [tiles]="stepTiles" [center]="true"></app-cms-tiles>
        </div>
      </div>
    </div>
  </div>

  <div class="pls-container py-3 mb-5" *ngIf="tiles?.length > 0">
    <app-cms-tiles [tiles]="tiles"></app-cms-tiles>
  </div>
</main>
