import { Component, OnInit } from '@angular/core';
import { CmsService } from "../../../cms/services/cms.service";

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {
  constructor(public cmsService: CmsService) { }

  ngOnInit(): void {
    
  }

  onGoHome() {
    window.location.href = "https://achieve.iowa.gov";
  }
}
