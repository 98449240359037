import { Component, Input, OnInit } from '@angular/core';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'app-course-detail-page',
  templateUrl: './course-detail-page.component.html',
  styleUrls: ['./course-detail-page.component.scss'],
})
export class CourseDetailPageComponent implements OnInit {
  @Input('model')
  public model;

  current: SiteMap = new SiteMap();

  // #region Video Section
  get displayVideoSection(): boolean {
    return !!this.backgroundImageUrl || !!this.videoSrc;
  }

  get backgroundImageUrl(): string {
    return this.model?.videoRegion?.backgroundImage?.media?.publicUrl;
  }

  get videoSrc(): string {
    return this.model?.videoRegion?.video?.media?.publicUrl;
  }

  get videoType(): string {
    return this.model?.videoRegion?.video?.media?.contentType;
  }
  // #endregion

  // #region Learn It Section
  get displayLearnItSection(): boolean {
    return this.model?.pageSummaryRegion?.summary?.value || this.model.excerpt;
  }

  get learnItSectionTitle(): string {
    return this.model?.pageSummaryRegion?.title?.value;
  }

  get learnItSectionBody(): string {
    return this.model?.pageSummaryRegion?.summary?.value
      ? this.model?.pageSummaryRegion?.summary?.value
      : this.model.excerpt;
  }
  // #endregion

  constructor(private viewNavigationService: ViewNavigationService) {
    this.subscribeCurrentViewNavigation();
  }

  ngOnInit(): void {}
  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
        //window.scrollTo(0, 0);
      });
    
  }
  
}
