<div
  class="pls-container"
  [ngClass]="{ 'height-adjustment': needsHeightAdjustment }"
>
  <for-each *ngFor="let block of model.blocks">
    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.ColumnBlock'">
      <div class="row">
        <div class="col-md my-0" *ngFor="let item of block.items">
          <!-- Probably be better to call this component recursively in the future to handle all potential types -->
          <ng-container *ngIf="item.type == 'Piranha.Extend.Blocks.HtmlBlock'">
            <div [innerHTML]="treateHrefs(item.body.value) | altAttr | safeHtml"
                 *ngIf="
              item.type != 'Piranha.Extend.Blocks.ImageBlock' &&
              hasInnerText(item.body.value)
            "></div>
          <mat-card [innerHTML]="item.body.value | altAttr | safeHtml"
                    *ngIf="
              item.type != 'Piranha.Extend.Blocks.ImageBlock' &&
              !hasInnerText(item.body.value)
            "></mat-card>
          </ng-container>
            <ng-container *ngIf="item.type == 'Piranha.Extend.Blocks.ImageBlock'">
              <mat-card>
                <img src="{{ item.body.media.publicUrl }}"
                     alt="{{ item.body.media.altText }}"
                     class="block__image" />
              </mat-card>
            </ng-container>
            <ng-container *ngIf="item.type == 'Piranha.Extend.Blocks.VideoBlock'">
              <div class="pls-container">
                <app-page-block-video [model]="item.body.media"></app-page-block-video>
              </div>
            </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.AudioBlock'">
      AudioBlock coming soon...
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.Comment'">
      Comment coming soon...
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.HtmlBlock'">
      <div
        [innerHTML]="treateHrefs(block.body.value) | altAttr | safeHtml"
        *ngIf="hasInnerText(block.body.value)"
      ></div>
      <mat-card
        [innerHTML]="block.body.value | altAttr | safeHtml"
        *ngIf="!hasInnerText(block.body.value)"
      ></mat-card>
    </ng-container>

    <mat-card *ngIf="block.type == 'Piranha.Extend.Blocks.ImageBlock'">
      <img
        src="{{ block.body.media.publicUrl }}"
        alt="{{ block.body.media.altText }}"
        class="block__image"
      />
    </mat-card>

    <ng-container
      *ngIf="block.type == 'Piranha.Extend.Blocks.ImageGalleryBlock'"
    >
      <div class="row my-2">
        <div class="col-md-12 col-lg-6" *ngFor="let item of block.items">
          <mat-card class="block__gallery">
            <img
              [src]="sanitizeURL(item.body.media.publicUrl)"
              [alt]="sanitizeURL(item.body.media.publicUrl)"
              class="block__image"
            />
          </mat-card>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.PageBlock'">
      Coming soon...
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.QuoteBlock'">
      <blockquote [innerHTML]="block.body.value"></blockquote>

      <footer class="blockquote-footer">
        <blockquote [innerHTML]="block.author.value"></blockquote>
      </footer>
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.SeparatorBlock'">
      <hr class="input__divider" />
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.TextBlock'">
      <div [innerHTML]="block.body.value  | altAttr | safeHtml"></div>
    </ng-container>

    <ng-container *ngIf="block.type == 'Piranha.Extend.Blocks.VideoBlock'">
      <div class="pls-container">
        <app-page-block-video [model]="block.body.media"></app-page-block-video>
      </div>
    </ng-container>
  </for-each>
</div>
