<div class="pls-container">
  <header class="cms-page__sub-header">
    <!--<h2>{{ subTitle }}</h2>-->

    <div class="breadcrumb">
      <a
        *ngFor="let item of breadcrumb; last as isLast"
        class="breadcrumb__item"
      >
        <a
           [routerLink]="'/cms' + item.permalink"
           class="text-underline-none">{{
          item.title
        }}</a>

        <svg
          *ngIf="!isLast"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="breadcrumb__spacer"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </a>
    </div>
  </header>
</div>
