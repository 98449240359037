import { SiteMap } from "./site-map.model"

export class ViewNavigation {
  current: SiteMap;
  parent: SiteMap;
  next: SiteMap;
  previous: SiteMap;
  siblings: SiteMap[];
  breadcrumb: SiteMap[];
  parentNextSibling: SiteMap;
}
