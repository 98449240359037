import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  private baseTitle = 'PLS';

  @Input() pageTitle: string;

  @Input() pageTitleColor = 'blue';

  @Input() helpLinks = false;

  @Input() isBold = true;

  @Input() showSubmitBtn = false;

  @Input() submitDisabled = false;

  @Input() condensed = false;

  @Input() backgroundColor:
    | 'red'
    | 'yellow'
    | 'blue'
    | 'blueDark'
    | 'purple'
    | 'white'
    | 'none' = 'white';

  @Input() navBack = false;

  @Output() helpToggled = new EventEmitter();

  @Output() navBackToggled = new EventEmitter();

  @Output() submitToggled = new EventEmitter();

  constructor(private readonly title: Title) {}

  ngOnInit() {
    let newTitle = this.pageTitle;
    if (newTitle) {
      newTitle += ` - ${this.baseTitle}`;
    } else {
      newTitle = this.baseTitle;
    }
    this.title.setTitle(newTitle);
  }

  onHelpToggled(e: Event) {
    this.helpToggled.emit(e);
  }

  onNavBackToggled(e: Event) {
    this.navBackToggled.emit(e);
  }

  onSubmitToggled() {
    this.submitToggled.emit();
  }
}
