<nav class="nav">
  <ul class="nav__list">
    <li class="nav__item">
      <a [routerLink]="['/']">
        <img src="/assets/img/{{
            cmsService.getCmsSpecificValue({
              iowaValue: 'iowa-logo.svg',
              coloradoValue: 'colorado-logo.svg'
            })
          }}"
             class="logo"
             alt="{{ cmsService.cmsSettings.getValue()?.project }} {{
            cmsService.cmsSettings.getValue()?.state
          }} Logo" />
      </a>
    </li>
  </ul>
</nav>

<section class="not-found">
  <div class="not-found__container">
    <main class="not-found__content">
      <div class="display-flex flex-column justify-content-between align-items-center">
        <section class="not-found__header">
          <h1 class="mb-0">401</h1>
        </section>

        <section class="not-found__info">
          <h2 class="mt-0">
            Sorry, we couldn't authenticate you.
          </h2>
          <h3>
            Please come back here through the Achieve application...
          </h3>
          <button color="primary"
                  mat-flat-button
                  class="not-found__cta"
                  (click)="onGoHome()">
            Go Home
          </button>
        </section>
      </div>
    </main>
  </div>
</section>
