import { first } from 'rxjs/operators';

import { CmsModule } from './cms/cms.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppMatModule } from './shared/app-mat.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NavModule } from './nav/nav.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppLoadModule } from './app-load.module';
import { VimeModule } from '@vime/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from "./interceptors/spinner-interceptor";


@NgModule({
  declarations: [AppComponent],
  imports: [
    AppLoadModule,
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    AppMatModule,
    SharedModule,
    DashboardModule,
    NavModule,
    CmsModule,
    VimeModule,
  ],
  exports: [VimeModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
