import { ViewNavigation } from '../models/view-navigation.modle';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewNavigationService } from '../services/view-navigation.service';
import { CmsService, CmsSettings } from '../services/cms.service';

@Component({
  selector: 'app-view-navigation',
  templateUrl: './view-navigation.component.html',
  styleUrls: ['./view-navigation.component.scss'],
})
export class ViewNavigationComponent implements OnInit {
  @Input() isCompletion = false;

  next: string = '';
  previous: string = '';
  parent: string = '';
  home: string = '';

  constructor(
    private router: Router,
    private viewNavigationService: ViewNavigationService, public readonly cmsService: CmsService) {
    this.subscribeCurrentViewNavigation();
  }
  ngOnInit(): void {}
  navigateTo(url: string) {
    this.router.navigate([`/cms/${url}`]);
  }

  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.next = this.getChildOrNext(nav);
        this.previous = this.getParentOrPrev(nav);
        this.parent = nav?.parent?.permalink;
        this.home = this.cmsService.cmsSettings.getValue()?.startPage;
      });
  }
  getParentOrPrev(nav: ViewNavigation) : string {
    if (nav?.previous) {
      return nav?.previous?.permalink;
    }
    return nav?.parent?.permalink;
  }
  getChildOrNext(nav: ViewNavigation): string {
    if (nav?.current?.items?.length !== 0) {
      return nav?.current?.items[0]?.permalink;
    } else if (!nav?.next?.permalink) {
      return nav?.parentNextSibling?.permalink ;
    }
    return nav?.next?.permalink;
  }
}
