import { Observable, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

export function lastValueFrom<T>(obs$: Observable<T>): Promise<T> {
  return obs$.toPromise();
}



export function firstValueFrom<T>(obs$: Observable<T>): Promise<T> {
  return obs$.pipe(first()).toPromise();
}
