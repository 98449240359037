import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { AuthUser } from '../models/auth-user';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {
  private props: {
     user: AuthUser
  }
  get authUser(): AuthUser {
    return this.props.user;
  }
  private set user(v: AuthUser) {
    this.props.user = v;
    this.publishAuthUserChanged();
  }
  private authUserChangeSource = new BehaviorSubject<AuthUser>(null);
  get authUserChanged$(): Observable<AuthUser> {
    return this.authUserChangeSource.asObservable();
  }
  constructor(
    private http: HttpClient
  ) {
    this.props = { user: <AuthUser>{}}
  }

  publishAuthUserChanged() {    
    this.authUserChangeSource.next(this.props.user);
  }

  authorize(id: string, token: string) {
    this.user = <AuthUser>{};
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http
        .post<AuthUser>('/api/authority/authorize', { app: id, token: token })
        .subscribe(
          this.processAuthUser(sub), 
          (err: any) => {
            this.user = null;
            sub.error(err);
        });
    });
  }
  hasSession() {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http
        .get<AuthUser>('/api/authority/has-session')
        .subscribe(
          this.processAuthUser(sub),
          (err: any) => {
            this.user = null;
            sub.error(err);
          });
    });
  }
  private processAuthUser(sub: Subscriber<any>) {
    return (user: AuthUser) => {
      this.user = user;
      sub.next(user);
      sub.complete();
    };
  }
}
