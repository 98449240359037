<div class="display-flex align-items-center text-left search-bar__container">
  <input (blur)="onBlur()"
         class=" search-bar__input" #myInput
         placeholder="Search" type="text" matInput
         [formControl] = "inputFormControl"
         (keyup.enter) = "onSearchSubmit()"
          >
  <button mat-icon-button
          (click)="close()">
    <mat-icon (click)="close(); myInput.focus()">close</mat-icon>
  </button>
  <button disableRipple="true" mat-icon-button (click)="onSearchSubmit(); myInput.focus()">
    <mat-icon>search</mat-icon>
  </button>
</div>

