import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService, CmsSettings } from 'src/app/cms/services/cms.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, public readonly cmsService: CmsService) {}

  ngOnInit(): void {
    
  }

  onGoHome() {
    this.router.navigate(['/']);
  }
}
