import { Component, Input, OnInit } from '@angular/core';
import { StandardPage } from 'src/app/cms/models/cms-standard-page.model';
import { PageSearchResult } from "../../../cms/models/page-search-result.model";

@Component({
  selector: 'app-pls-search-result-list',
  templateUrl: './pls-search-result-list.component.html',
  styleUrls: ['./pls-search-result-list.component.scss'],
})
export class PlsSearchResultListComponent implements OnInit {
  @Input() results: Array<PageSearchResult>;
  @Input() searchText: string;

  constructor() {}

  ngOnInit(): void {}
}
