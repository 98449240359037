<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>

<div class="pls-container pls-completion">
  <h2 class="mt-0 mb-1">Congratulations! This Mini Mod is Complete.</h2>
  <p
    class="mt-0 mb-3"
    *ngIf="model?.pageSummaryRegion?.textSummary?.value || model.excerpt"
  >
    {{
      model?.pageSummaryRegion?.textSummary?.value
        ? model?.pageSummaryRegion?.textSummary?.value
        : model.excerpt
    }}
  </p>
</div>

<!--<ng-template #defaultSummary>
  Click Continue to continue to the next Mini Mod. Click the Home button
  to return to Mini Mod selection.
</ng-template>-->

<app-page-blocks [model]="model"></app-page-blocks>

<section class="pls-secondary">
  <div class="pls-container">
    <app-view-navigation [isCompletion]="true"></app-view-navigation>
  </div>
</section>
