import { Component, OnInit } from '@angular/core';
import { CmsService, CmsSettings } from '../../services/cms.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  
  constructor(private router: Router, public cmsService: CmsService) {
    
  }

  ngOnInit(): void {
    this.cmsService.cmsSettings.subscribe((response: CmsSettings) => {
      this.router.navigate(['cms', response.startPage]);
    });
  }
}
