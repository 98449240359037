import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteMap } from '../../models/site-map.model';
import { ViewNavigation } from '../../models/view-navigation.modle';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'app-course-start-page',
  templateUrl: './course-start-page.component.html',
  styleUrls: ['./course-start-page.component.scss'],
})
export class CourseStartPageComponent implements OnInit {
  @Input('model')
  public model;

  current: SiteMap = new SiteMap();
  tiles: Array<any> = [];

  myUrl: string = "";
  mySlug: string = "";
  

  constructor(private router: Router, private viewNavigationService: ViewNavigationService) {}

  ngOnInit(): void {
    this.getTiles();
  }

  getNavigationLink(slug: string): Array<string> {
    return [`/cms/${slug}`];
  }

  getTiles(): void {
    const regionIteration = [1, 2, 3, 4, 5, 6, 7, 8, 9];    
    regionIteration.forEach((iteration) => {
      this.myUrl = this.model.ninePageLinksRegion[`linkUrl${iteration}`]?.value;
      if (this.myUrl?.indexOf("/cms/") > -1) {
        this.mySlug = this.myUrl?.split('?')[0].split('/').pop();
        this.tiles.push({
          link: "/" + this.mySlug,
          title: this.model.ninePageLinksRegion[`buttonText${iteration}`]?.value,
        });        
      } else {
        this.tiles.push({
          externalLink: this.model.ninePageLinksRegion[`linkUrl${iteration}`]?.value,
          title: this.model.ninePageLinksRegion[`buttonText${iteration}`]?.value,
        });
      };

    });
  }


  subscribeCurrentViewNavigation() {
    this.viewNavigationService
      .currentViewNavigation()
      .subscribe((nav: ViewNavigation) => {
        this.current = nav.current ?? new SiteMap();
//        window.scrollTo(0, 0);
      });
  }
}
