<app-cms-page-header [pageTitle]="model.title"></app-cms-page-header>

<section
  class="pls-video"
  style="background-image: url('{{
    model?.videoRegion?.backgroundImage?.media?.publicUrl
  }}') !important"
  [ngClass]="{ 'pls-video--no-video': true }"
  *ngIf="model?.videoRegion?.backgroundImage?.media?.publicUrl !== null"
></section>

<section>
  <div class="pls-container">
    <ul>
      <li>
        {{ current.menuTitle }}
        <ul>
          <ng-container
            *ngTemplateOutlet="
              recursiveListTmpl;
              context: { list: current.items }
            "
          ></ng-container>
        </ul>
      </li>
    </ul>
  </div>
</section>
<ng-template #recursiveListTmpl let-list="list">
  <li *ngFor="let item of list">
    {{ item.menuTitle }}
    <ul *ngIf="item.items.length > 0">
      <ng-container
        *ngTemplateOutlet="recursiveListTmpl; context: { list: item.items }"
      ></ng-container>
    </ul>
  </li>
</ng-template>
