export interface HelpDictionary {
  label: ExampleHelp;
  descriptor?: string;
  iframeSource?: string;
}

export interface HelpText {
  question?: string;
  answer?: string | any[];
}

export enum ExampleHelp {
  WebDev = 'Web Development',
  WebDes = 'Web Design',
}
