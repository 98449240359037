import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '../../components/help/help-modal/help-modal.component';
import { HelpTextSheetComponent } from '../../components/help/help-text-sheet/help-text-sheet.component';
import { HelpDictionary, HelpText, ExampleHelp } from './help';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(private bottomSheet: MatBottomSheet, private dialog: MatDialog) {}

  openHelpSheet(help: HelpText[]) {
    this.bottomSheet.open(HelpTextSheetComponent, {
      data: { help },
      panelClass: 'help-sheet',
    });
  }

  openHelpModal(
    dictionary: HelpDictionary[],
    selectedItem: string,
    canBrowse: boolean
  ) {
    const data = {
      dictionary,
      selectedItem,
      canBrowse,
    };

    const dialogRef = this.dialog.open(HelpModalComponent, {
      hasBackdrop: true,
      width: '1280px',
      data,
    });
  }

  getAllDictionaries(): HelpDictionary[] {
    return [].concat(this.getExampleDictionary());
  }

  getExampleDictionary(): HelpDictionary[] {
    return [
      {
        label: ExampleHelp.WebDev,
        descriptor: `Web development is the work involved in developing a Web site for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.`,
      },
      {
        label: ExampleHelp.WebDes,
        descriptor: `Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design; user interface design; authoring, including standardized code and proprietary software; user experience design; and search engine optimization.`,
      },
    ];
  }
}
