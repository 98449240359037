import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-directional-text',
  templateUrl: './help-directional-text.component.html',
  styleUrls: ['./help-directional-text.component.scss'],
})
export class HelpDirectionalTextComponent implements OnInit {
  @Input() help: string;

  constructor() {}

  ngOnInit(): void {}
}
