import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppMatModule } from '../shared/app-mat.module';
import { RouterModule } from '@angular/router';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { PlsSearchResultListComponent } from './components/pls-search-result-list/pls-search-result-list.component';
import { PlsSearchResultComponent } from './components/pls-search-result/pls-search-result.component';
import { CmsModule } from '../cms/cms.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
@NgModule({
  declarations: [
    SearchComponent,
    PlsSearchResultListComponent,
    PlsSearchResultComponent,
    SearchBarComponent,
  ],
  exports: [
    SearchBarComponent
  ],
  imports: [
    CommonModule,
    SearchRoutingModule,
    RouterModule,
    AppMatModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule

  ],
})
export class SearchModule {}
