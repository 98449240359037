import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ExampleHelp } from '../shared/services/help/help';
import { HelpService } from '../shared/services/help/help.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  exampleHelp = ExampleHelp;
  displayedColumns = ['num', 'name', 'gender', 'age', 'dob', 'city', 'power'];
  dataSource = new MatTableDataSource([
    {
      num: 1,
      name: 'Peter Parker',
      gender: 'Male',
      age: '19',
      dob: 'August 10, 2001',
      city: 'Manhattan, New York',
      power: 'Spider Powers',
    },
    {
      num: 2,
      name: 'Bruce Wayne',
      gender: 'Male',
      age: '48',
      dob: 'January 3, 1973',
      city: 'Gotham City',
      power: 'None',
    },
  ]);

  items: { label: string; content: string }[] = [
    {
      label: 'Label 1',
      content: 'Content 1',
    },
    {
      label: 'Label 2',
      content: 'Content 2',
    },
    {
      label: 'Label 3',
      content: 'Content 3',
    },
  ];

  constructor(private helpService: HelpService) {}

  ngOnInit(): void {}

  onOpenHelp(selectedItem: ExampleHelp) {
    const dictionary = this.helpService.getExampleDictionary();
    this.helpService.openHelpModal(dictionary, selectedItem, false);
  }
   

}
