import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteMap } from '../models/site-map.model';
import { StandardPage } from '../models/cms-standard-page.model';
import { Media } from "../models/cms.model";
import { PagedResult } from "../models/paged-result";
import { PageSearchResult } from "../models/page-search-result.model";

export interface CmsSettings {
  state: 'Iowa' | 'Colorado';
  project: 'Ascend' | 'ACHIEVE';
  startPage: "|";
}

export interface CmsSpecificConfig {
  iowaValue: string;
  coloradoValue: string;
}

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  public cmsSettings = new BehaviorSubject<CmsSettings>(null);

  constructor(private http: HttpClient) {}

  getSiteMap(): Observable<SiteMap[]> {
    return this.http.get<SiteMap[]>(`api/sitemap`);
  }

  getStandardPage(url: string): Observable<StandardPage> {
    return this.http.get<StandardPage>(`api/page/${url}`);
  }

  public getCmsSettings(): Observable<CmsSettings> {
    return this.http.get<CmsSettings>(`api/cms/config`);
  }

  public updateCmsSettings(cmsSettings: CmsSettings) {
    this.cmsSettings.next(cmsSettings);
  }

  public getCmsSpecificValue(config: CmsSpecificConfig): string {
    switch (this.cmsSettings.getValue()?.state) {
      case 'Iowa':
        return config.iowaValue;
      case 'Colorado':
        return config.coloradoValue;
      default:
        return null;
    }
  }

  public getMedia(id: string): Observable<Media> {
    return this.http.get<Media>(`api/media/${id}`);
  }

  public search(s: string, pageIndex: number, pageSize: number): Observable<PagedResult<PageSearchResult[]>> {
    return this.http.get<PagedResult<PageSearchResult[]>>(`api/page/?s=${s}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
  }
}
