import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cms-page-header',
  templateUrl: './cms-page-header.component.html',
  styleUrls: ['./cms-page-header.component.scss'],
})
export class CmsPageHeaderComponent implements OnInit {
  @Input() pageTitle: string;

  constructor() {}

  ngOnInit(): void {}
}
