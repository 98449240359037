import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "altAttr" })
export class AltAttributePipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
   return this.transformEl(this.transformEl(value, 'img'), 'icon');
  }
  private transformEl(value: string, tag: string) {
    const div = document.createElement('div');
    div.innerHTML = value;
    let els = div.getElementsByTagName(tag);
    for (let i = 0; i < els.length; i++) {
      let el = els[i];
      if (!el.hasAttribute('alt')) {
        el.setAttribute('alt', '');
      }
    }
    return div.innerHTML;
  }
}
